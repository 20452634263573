<template>
  <v-container>
    <DashboardItems />
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    DashboardItems: () => import("@/components/common/DashboardItems"),
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
